<template>
  <div class="modal-update modal">
    <div class="modal-content">
      <template v-if="step === 1">
        <div class="body text-center">
          <Loading className="cms" />
          <h1 class="mt-2">Actualizando {{ screen }}</h1>
          <p>Espere porfavor ..</p>
        </div>
      </template>

      <template v-else>
        <div class="header">
          <div class="header-icon">
            <i v-if="success" class="fas fa-check-circle"></i>
            <i v-else class="fas fa-times-circle"></i>
          </div>
        </div>
        <div class="body text-center">
          <h1 class="mt-1 message-update">
            {{ message }}
          </h1>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    screen: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    message: {
      type: String,
    },
    success: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Loading: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Loading" */ '@/components/Loading.vue')
    ),
  },
};
</script>
